html {
  height: 100%;
}
body {
  background-color: #5f9ea0;
}
.container {
  margin-top: 20px;
}
.contentArea {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 20px;
}
.d-grid {
  margin-top: 20px;
}
.submitBtn {
  width: 100%;
}